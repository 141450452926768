import $ from "jquery";

$(document).ready($ => {
  $("#dropdown-link").on("click", e => {
    e.preventDefault();
    $(".dropdown-content").toggleClass("open");
    $(".icon-arrow-down").toggleClass("deactive");
  });

  $("body").on("click", e => {
    if (!$(e.target).closest("#dropdown-link").length) {
      $(".dropdown-content").removeClass("open");
      $(".icon-arrow-down").removeClass("deactive");
    }
  });

  // Mobile menu //

  $("#dropdown-link-mobile").on("click", e => {
    e.preventDefault();
    $(".dropdown-content-mobile").toggleClass("open");
    $(".icon-arrow-down-mobile").toggleClass("deactive");
  });

  $("body").on("click", e => {
    if (!$(e.target).closest("#dropdown-link-mobile").length) {
      $(".dropdown-content-mobile").removeClass("open");
      $(".icon-arrow-down-mobile").removeClass("deactive");
    }
  });
});
