import $ from "jquery";
import List from "list.js";
import debounce from "debounce";

/* List.js - Sort */
var options = {
  valueNames: ["name", "date"]
};
var files = new List("files", options);

$(document).ready($ => {
  /* Load more Nyheter */
  $("#load-older-posts").on("click", function(e) {
    e.preventDefault();
    var button = $(this);
    var container = $(".nyheter-wrap");
    var page = parseInt(button.attr("data-paged"));
    var total = parseInt(button.attr("data-total"));
    var nextPage = page + 1;

    button
      .text("Laddar...")
      .prop("disabled", true)
      .addClass("disabled");

    $.ajax({
      type: "POST",
      url: ajaxurl,
      data: {
        action: "news_load_more",
        page: page,
        total: total
      },
      success: function(response) {
        $(".nyheter-wrap").append(response);

        button
          .text("Se fler nyheter")
          .prop("disabled", false)
          .removeClass("disabled");

        button.attr("data-paged", nextPage);
        var lastCount = container.find(".nyhet").length;
        if (total <= lastCount) {
          button.text("Inga fler nyheter");
          button.prop("disabled", true);
          button.addClass("disabled");
        }
        return false;
      }
    });
  });

  /* Load more Documents */
  $("#load-older-documents").on("click", function(e) {
    e.preventDefault();
    var button = $(this);
    var container = $("#document-wrap");
    var page = parseInt(button.attr("data-paged"));
    var total = parseInt(button.attr("data-total"));
    var nextPage = page + 1;
    var counter = 0;
    var totalDOM = $("#document-wrap li");

    totalDOM.each(function() {
      counter++;
    });

    var li = $(".sort-by li.asc, .sort-by li.desc");
    var dataSortVal = li.attr("data-sort");

    button
      .text("Laddar...")
      .prop("disabled", true)
      .addClass("disabled");

    $.ajax({
      type: "POST",
      url: ajaxurl,
      data: {
        action: "documents_load_more",
        page: page,
        total: total,
        counter: counter
      },
      success: function(response) {
        $("#document-wrap").append(response);
        files.reIndex();

        if (dataSortVal) {
          files.sort(dataSortVal);
        }

        button
          .text("Se fler dokument")
          .prop("disabled", false)
          .removeClass("disabled");

        button.attr("data-paged", nextPage);
        var lastCount = container.find(".file").length;

        if (total <= lastCount) {
          button.text("Inga fler dokument");
          button.prop("disabled", true);
          button.addClass("disabled");
        }

        return false;
      }
    });
  });

  /* Dropdown Sort */
  $(".sort-wrap .icon-arrow-down, .sort-text").on("click", function(e) {
    e.preventDefault();
    $(".sort-by").toggleClass("open");
    $(".sort-wrap .icon-arrow-down").toggleClass("open");
  });

  $("body").on("click", e => {
    if (!$(e.target).closest(".sort-wrap").length) {
      $(".sort-by").removeClass("open");
      $(".sort-wrap .icon-arrow-down").removeClass("open");
    }
  });

  /* Search all Documents */
  $(".search-document").keyup(
    debounce(function(e) {
      e.preventDefault();
      var search = $(".search-document").val();
      var total = $("#document-wrap").attr("data-total");

      var counter = 0;
      var totalDOM = $("#document-wrap li");

      totalDOM.each(function() {
        counter++;
      });

      var li = $(".sort-by li.asc, .sort-by li.desc");
      var dataSortVal = li.attr("data-sort");

      $.ajax({
        type: "POST",
        url: ajaxurl,
        data: {
          action: "documents_load_more",
          search: search,
          total: total
        },
        success: function(response) {
          $(".document-wrap").html(response);

          files.reIndex();

          if (dataSortVal) {
            files.sort(dataSortVal);
          }
          return false;
        }
      });
    }, 300)
  );
});

// Remove load more button if on search
$("input[name=search-document]").on("keyup", e => {
  if (!$(".search-document").val()) {
    $("#load-older-documents").removeClass("hidden");
  } else {
    $("#load-older-documents").addClass("hidden");
  }
});
