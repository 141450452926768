import $ from "jquery";

$(document).ready(function($) {
  $("a#show-login").on("click", function(e) {
    $("body").prepend('<div class="login-overlay"></div>');
    $("form#login").fadeIn(120);
    $("div.login-overlay, form#login a.close").on("click", function() {
      $("form#login").fadeOut(120, function() {
        $("div.login-overlay").remove();
        $("form#login").hide();
      });
    });
    e.preventDefault();
  });

  $("form#login").on("submit", function(e) {
    e.preventDefault();
    $("form#login p.status").show();
    $.ajax({
      type: "POST",
      dataType: "json",
      url: ajaxurl,
      data: {
        action: "loginAjax",
        email: $("form#login #email").val(),
        password: $("form#login #password").val(),
        security: $("form#login #security").val()
      },
      success: function(data) {
        $("form#login p.status").text(data.message);
        if (data.loggedin == true) {
          window.location.reload(true);
        }
      }
    });
  });

  $(".js-logout").on("click", function(e) {
    e.preventDefault();
    $.ajax({
      type: "POST",
      dataType: "json",
      url: ajaxurl,
      data: {
        action: "logoutAjax"
      },
      success: function(data) {
        if (data.loggedout == true) {
          document.location.href = data.redirecturl;
        }
      }
    });
  });
});
