import $ from "jquery";

$(document).ready($ => {
  $("#change-password").on("click", e => {
    e.preventDefault();
    $("#show-change-password").addClass("show");
  });

  $("#form-change-password").on("submit", e => {
    e.preventDefault();
    var password = $("#password").val();
    var confirmPassword = $("#confirm-password").val();

    $.ajax({
      type: "POST",
      dataType: "json",
      url: ajaxurl,
      data: {
        action: "change_password",
        password: password,
        confirmPassword: confirmPassword
      },
      success: data => {
        $("#message").text(data.message);

        if (data.changed) {
          $("#message")
            .addClass("success")
            .text(data.message);
        }
      }
    });
  });
});
